
import { IonContent, IonItem } from '@ionic/vue';
import { defineComponent, toRefs, PropType } from 'vue';
import {
  ionAlert,
} from "@/composables/ionicControllers";

type Options = {
  disable: () => boolean
  handler1: () => void
  handler2: () => void
}

export default defineComponent({
  name: 'Popover',
  components: { IonContent, IonItem },
  props: {
    close: { type: Function },
    data: { type: Object },
    popoverOptions: Object as PropType<Options>,
  },
  setup(props) {
    const { popoverOptions, data } = toRefs(props)
    const { disable, handler1, handler2 }: any = popoverOptions.value

    const openAlert = (type: string, text: string) => {
      return ionAlert({
        cssClass: 'custom-alert',
        header: 'Confirm!',
        message: `Are you sure you want to ${text}`,
        cancelButton: {
          text: 'No',
          role: 'cancel',
          cssClass: 'cancel-button',
          handler: () => null
        },
        confirmButton: {
          text: 'Yes',
          role: 'confirm',
          cssClass: 'confirm-button',
          handler: () => {
            if (type === 'edit') {
              return handler1(data.value)
            }
            return handler2(data.value)
          }
        }
      })
    }

    return {
      disable,
      openAlert
    }
  }
});
