
import { defineComponent, ref, onMounted, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { merchants } from '@/store';
import {
  IonContent,
  IonPage,
  IonButtons,
  IonToolbar,
  IonHeader,
  IonBackButton,
  IonTitle,
  IonText,
  IonButton,
  IonIcon,
  IonItem,
} from '@ionic/vue';
import { ellipsisVertical, checkmarkOutline } from 'ionicons/icons';
import Popover from './components/Popover.vue';
import {
  popover,
  loading,
  openToast,
} from '@/composables/ionicControllers';
import { Account } from '@/types/merchant';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    IonTitle,
    IonText,
    IonButton,
    IonIcon,
    IonItem,
  },
  setup() {
    const router = useRouter();
    const merchantAccounts = ref<any[]>([]);
    const { merchant } = toRefs(merchants.state);
    const { getMerchantAccounts } = merchants;
    const navigate = async () =>
      await router.push('/app/payment/add-new');
    onMounted(async () => {
      await getMerchantAccounts();
      if (merchant.value) {
        if (merchant.value.accounts?.all) {
          merchantAccounts.value = Object.values(
            merchant.value.accounts.all,
          );
        }
      }
    });

    watch(merchant, () => {
      if (merchant.value) {
        if (merchant.value.accounts?.all) {
          merchantAccounts.value = Object.values(
            merchant.value.accounts.all,
          );
        }
      }
    });

    const disableOption = (bank: Account) =>
      bank.accountNumber.toString() ===
      merchant.value?.accounts?.default;
    const deleteMerchantAccount = async (bank: Account) => {
      if (
        bank.accountNumber.toString() ===
        merchant.value?.accounts?.default
      ) {
        await openToast(
          'cannot delete your default bank option. please set a new option before delete',
        );
        return;
      }
      const loader = await loading();
      if (merchant.value) {
        await loader.present();
        const response: any = await merchants.deleteMerchantAccount(
          bank.accountNumber.toString(),
        );
        if (response.ok) {
          await loader.dismiss();
          await openToast(response.message);
          return;
        } else {
          await loader.dismiss();
          await openToast(response.message);
          return;
        }
      }
    };
    const setMerchantDefaultAccount = async (bank: Account) => {
      const loader = await loading();
      if (merchant.value?.accounts?.default) {
        await loader.present();
        const response: any = await merchants.setDefaultMerchantAccount(
          bank,
        );
        if (response.ok) {
          merchant.value.accounts.default = bank.accountNumber.toString();
          await loader.dismiss();
          await openToast(response.message);
          return;
        } else {
          await loader.dismiss();
          await openToast(response.message);
          return;
        }
      }
    };
    const isDefault = (bank: Account): boolean => {
      return (
        bank?.accountNumber.toString() ===
        merchant.value?.accounts?.default
      );
    };

    const popoverOptions = {
      disable: disableOption,
      handler1: setMerchantDefaultAccount,
      handler2: deleteMerchantAccount,
    };

    const openPopover = async (e: Event, data: Account) =>
      popover(e, Popover, { data, popoverOptions }, 'popover');

    return {
      pageTitle: 'Payment',
      merchantAccounts,
      navigate,
      openPopover,
      ellipsisVertical,
      checkmarkOutline,
      isDefault,
    };
  },
});
